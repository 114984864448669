
export default {
  props: {
    action: {
      required: true,
      type: Function
    },
    enabled: { default: true }
  }
}
